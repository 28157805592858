export const dashboard = {
    action: "fa fa-home",
    title: "Home",
    permission: "dashboard",
    items: [
        {
            title: "Home",
            prefix: "I",
            link: "/dashboard",
            permission: "",
        },
        {
            title: "مؤشر أداء تسليم مواقع مشعر مزدلفة",
            prefix: "I",
            link: "/dashboard-power-bi",
            permission: "dashboard-power-bi-1",
        },
        {
            title: "مؤشرات أداء الاضافة",
            prefix: "I",
            link: "/addition",
            permission: "dashboard-additions-stage",
        },
        {
            title: "المعلومات الجغرافية",
            prefix: "G",
            link: "/gis-dashboard",
            permission: "dashboard-gis-1",
        },
    ],
};