import Vue from 'vue'
class UserService {
    get_users(page, data) {
        return axios.post('users?page=' + page, data)
    }
    switch_user(id, data) {
        return axios.post('users/switch-user-status/' + id, data, {
            baseURL: Vue.prototype.$baseMenaURL + 'api'
        })
    }
    edit_role_user(id, data) {
        return axios.post('users/update-user-role/' + id, data)
    }

    add_alliances(data) {
        return axios.post('general/alliance/store_with_members', data)
    }
    edit_alliance(data) {
        return axios.post('general/alliance/update', data)
    }
    change_leader(data) {
        return axios.post('general/alliance/change_Leader', data)
    }
    remove_member(data) {
        return axios.post('general/alliance_members/remove_member', data)
    }
    add_member(data) {
        return axios.post('general/alliance_members/store', data)
    }
    switch_user_affiliations(id) {
        return axios.post('users/set_as_entity/' + id, {}, {
            baseURL: Vue.prototype.$baseMenaURL + 'api'
        })
    }
}
export default new UserService();