import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";

const Dashboard = () =>
    import("@/modules/dashboard/views/Dashboard.vue");
const DashboardPowerBI = () =>
    import("@/modules/dashboard/views/DashboardPowerBI.vue");
const AssignCampDashboard = () =>
    import("@/modules/dashboard/views/AssignCampDashboard.vue");
const AdditionDashboard = () =>
    import("@/modules/dashboard/views/AdditionDashboard.vue");
const gisDashboard = () => import("@/modules/dashboard/views/DashboardGis.vue");

export const dashboardPage = {

    path: "/",
    name: "Dashboard",
    component: DashboardLayout,
    children: [

        {
            path: "/dashboard",
            name: "Default",
            component: Dashboard,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "",
            },
        },
        {
            path: "/dashboard-power-bi",
            name: "PowerBIDashboard",
            component: DashboardPowerBI,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "dashboard-power-bi-1",
            },
        },
        {
            path: "/camp-delivery-performance",
            name: "CampDeliveryPerformanceIndex",
            component: AssignCampDashboard,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "dashboard-delivred-camp",
            },
        },
        {
            path: "/addition",
            name: "AdditionDashboard",
            component: AdditionDashboard,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "dashboard-additions-stage",
            },
        },
        {
            path: "/gis-dashboard",
            name: "gisDashboard",
            component: gisDashboard,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permission: "dashboard-gis-1",

            },
        },
    ]

}