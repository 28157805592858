import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import BlankLayout from "../views/Layout/BlankLayout.vue";
import AuthCoverLayout from "@/views/Layout/AuthCoverLayout";
import Middleware from "@/middleware/";

const FileForm = () => import("@/modules/assign_camp/views/Appointments/FileForm.vue");
const BlankRegisterDone = () => import("@/modules/auth/views/Done.vue");

Vue.use(VueRouter);

/************** Start Routes ***********************/

let FilePage = {
    path: "/",
    component: BlankLayout,
    name: "FileSignaturePage",
    children: [
        {
            path: "appointments/:id/form/:form/view",
            name: "AppointmentFormView",
            component: FileForm,
            meta: {
                groupName: "AnswerForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "delivery-view",
            },
        },


        {
            path: "/done",
            name: "BlankRegisterDone",
            component: BlankRegisterDone,
            meta: {
                groupName: "Done",
                middlewares: [Middleware.authReject],
            },
        },

        {
            path: "/orders/license/:id",
            name: "LicenseOrder",
            component: () =>
                import("@/modules/orders/views/Orders/Files/License.vue"),
            meta: {
                groupName: "Order",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-license",
            },
        },
        {
            // https://http://localhost:8082/orders/qr/UjLMP7i4Mscy7wGUneYjVNy79M60BN
            path: "/orders/qr/:qr",
            name: "BlankCheckQrOrder",
            component: () => import("@/modules/orders/views/Orders/CheckQr.vue"),
            meta: {
                groupName: "Order",
            },
        },
        {
            path: "/re-assign-orders/:id/re-delivery/file",
            name: "ReAssignReDeliveryFile",
            component: () => import("@/modules/reassign/views/re_delivery/File.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-re-delivery-view",
            },
        },
        {
            path: "re-assign-orders/:id/form/:form/view",
            name: "ReAssignFormShowOrder",
            component: () => import("@/modules/reassign/views/form/Show.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-form-view",
            },
        },
    ],
};

let PageError = {
    path: "/error",
    component: AuthCoverLayout,
    name: "Errors",
    children: [{
        path: "/403",
        meta: {

            title: "403",
        },
        name: "403",
        component: () =>
            import("@/views/Error/403.vue"),
    },
        // {
        //     path: "/404",
        //     name: "404",
        //     component: () =>
        //         import("@/views/Error/403.vue"),
        // },
    ],
};
import {
    dashboardPage,
} from "@/modules/dashboard/routes/dashboard"


import {
    Login,
    SignUp,
    RegisterFormPages,
} from "@/modules/auth/routes/auth"

import {
    SquaresPage,
    campsPage,
    AssignsPage,
    AppointmentsPage,
    CountryPage

} from "@/modules/assign_camp/routes/camps";

import StationPage from "@/modules/assign_camp/routes/station";
import {

    questionsPage,
    questionCategoryPage,
    AnswerFromPage,
    TamplateFormPage
} from "@/modules/form/routes/form"

import { Roles, TypesPage, EmployeePage, Admins, categoriesPage, alliances } from "@/modules/users/routes/users";
import ticket from "@/modules/tickets/routes/ticket";
import ticket_subject from "@/modules/tickets/routes/subject";
import LicensePage from "@/modules/license/routes/license";
import orders from "@/modules/orders/routes/order";
import service from "@/modules/orders/routes/service";
import pledge from "@/modules/orders/routes/pledge";
import EngGuides from "@/modules/orders/routes/EngGuides";
import TmpLicensePage from "@/modules/orders/routes/license";
import reAssignOrder from "@/modules/reassign/routes/reassign_order"

/************** End Routes ***********************/
const routes = [
    {
        path: "/",
        name: "Dashboard",
        redirect: "/dashboard",
        component: DashboardLayout,
        children: [
            {
                path: "/testing-api",
                name: "test",
                component: () => import("@/views/Current/Components/Test.vue"),
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.is_admin,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    // permissions: "dashboard-optimization-sites",
                },
            },
        ],

    },

    dashboardPage,
    Login,
    RegisterFormPages,
    SignUp,
    CountryPage,
    campsPage,
    SquaresPage,
    AssignsPage,
    AppointmentsPage,
    questionsPage,
    questionCategoryPage,
    AnswerFromPage,
    TamplateFormPage,
    categoriesPage,
    EmployeePage,
    TypesPage,
    Roles,
    Admins,
    FilePage,
    PageError,
    alliances,
    ticket,
    ticket_subject,
    LicensePage,
    orders,
    service,
    pledge,
    EngGuides,
    TmpLicensePage,
    StationPage,
    reAssignOrder,
    {
        path: "*",
        component: AuthCoverLayout,
        name: "Error",
        redirect: "/404",
        children: [
            {
                path: "/404",
                meta: {
                    title: "404",
                },
                name: "404",
                component: () => import("@/views/Error/404.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});


import i18n from '@/i18n.js'
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        if (to.meta.title)
            document.title = i18n.t(to.meta.title);
    });
});

export default router;
