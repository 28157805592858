import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "ReAssignOrders",
    children: [


        {
            path: "/re-assign-orders/:id/form/:form",
            name: "ReAssignFormOrder",
            component: () => import("@/modules/reassign/views/form/Form.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-form-answer",
            },
        },

        {
            path: "/re-assign-orders/:id/form/:form/approve-camps",
            name: "ReAssignFormOrderFormApprove",
            component: () => import("@/modules/reassign/views/form/FormCampApproveByCompany.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-company-form-answer",
            },
        },


        {
            path: "/re-assign-orders/:id/payment-attachment",
            name: "ReAssignPaymentAttachmentOrder",
            component: () => import("@/modules/reassign/views/uploadPaymentAttachment.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "",
            },
        },

        {
            path: "/re-assign-orders/:id/payment-attachment/view",
            name: "ReAssignProccessPaymentAttachmentOrder",
            component: () => import("@/modules/reassign/views/proccessPaymentAttachment.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-payment-attachment",
            },
        },

        {
            path: "/re-assign-orders/:id/re-delivery/signature",
            name: "ReAssignReDeliverySignature",
            component: () => import("@/modules/reassign/views/re_delivery/single/Signature.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-re-delivery-signature",
            },
        },

        {
            path: "/about-signature",
            name: "AboutSignatureReassign",
            component: () => import("@/modules/reassign/views/re_delivery/NeedSignature.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-signature",
            },
        },

        // {
        //     path: "/bulk-signature",
        //     name: "BulkSignatureReassign",
        //     component: () => import("@/modules/reassign/views/re_delivery/bulk/BulkSignature.vue"),
        //     meta: {
        //         groupName: "ReAssignOrders",
        //         middlewares: [
        //             Middleware.auth,
        //             Middleware.permission,
        //             Middleware.signeture,
        //         ],
        //         permissions: "re-assign-order-bulk-signature",
        //     },
        // },


        {
            path: "/re-assign-orders",
            name: "ReAssignOrderPage",
            component: () => import("@/modules/reassign/views/Show.vue"),
            meta: {
                groupName: "ReAssignOrders",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-re-assign-index",
            },
        },

    ]
};